import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";

const Admindisclaimer = () => {


    const [aboutData, setAboutData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [updatedsDescription, setUpdatedsDescription] = useState('');
    const [title, setTitle] = useState('');




    const [file, setFile] = useState('');
    const [pending, setPending] = useState(true);

    const actualData1 = aboutData?.map((data) => data.description).toString();
    const actualData2 = aboutData?.map((data) => data.short_description).toString();


    const notify = () => toast.info("Disclaimer Updated SuccessFully", { position: "top-center" });

    const getData = () => {
        axios.get('/getPrivacy/2').then((response) => {
            setAboutData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }


    useEffect(() => {
        getData();

    }, [])
    useEffect(() => {
        setTitle(aboutData[0]?.content);
    }, [aboutData])

    const handleUpdate = () => {

        const formData = new FormData();
        formData.append("file", file);


        axios.post('/updateDisclaimer', {
            id: 1,
            content: updatedDescription,
        },

            {
                method: 'POST',
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                if (response.status === 200) {
                    notify();
                }

            }).catch((err) => {

                console.log(err);
            })
    }

    return (
        <>

            <Helmet>
                <title>Walcoseed | Disclaimer</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                    <ToastContainer />
                        <div class="col-lg-12">
                            <section class="cardSectionBorder">
                                

                                <span className=''>Disclaimer</span>
                                <div className='mb-3 mt-3'>
                                    <TextEditor data={title} setUpdatedContent={setUpdatedDescription} />
                                </div>


                               
                                <hr></hr>
                                <div>
                                    <Button className='Add_btn_' onClick={handleUpdate}>Update</Button>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default Admindisclaimer
