import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet";

const Adminwhychoose = () => {


    const [aboutData, setAboutData] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [updatedsDescription, setUpdatedsDescription] = useState('');
    const [title, setTitle] = useState('');

    
    
    const [file, setFile] = useState('');
    const [pending, setPending] = useState(true);

    const actualData = aboutData?.map((data) => data.title).toString();
    const actualData1 = aboutData?.map((data) => data.description).toString();


    const notify = () => toast.info("Why Choose Us Updated SuccessFully",{position : "top-center"});

    const getData = () => {
        axios.get('/getadminwhyChoose').then((response) => {
            setAboutData(response.data.data);
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
          })
    }


    useEffect(() => {
        getData();

    }, [])

    useEffect(() => {
        setTitle(aboutData[0]?.title);
    }, [aboutData])
   

    const handleUpdate = () => {

        const formData = new FormData();
        formData.append("file", file);
        

        axios.post('/updateWhy', {
            id:1,
            title: title,
            description: updatedDescription,
            image: file 
        },
            
            {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);

            }

        }).catch((err) => {
            
            console.log(err);
          })
    }

    return (
        <>

        <Helmet>
            <title>Walcoseed | Why Choose Us</title>
        </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                    <ToastContainer />
                        <div class="col-lg-12">
                            <section class="cardSectionBorder">
                                
                                
                            <span className=''>Title</span>
                                <textarea className='form-control mb-3 mt-3' rows={2}
                                    value={title}
                                    type="text"
                                    onChange={(e) => setTitle(e.target.value)}
                                />

                                <span className=''>Description</span>
                                <div className='mb-3 mt-3'>
                                    <TextEditor data={actualData1} setUpdatedContent={setUpdatedDescription} />
                                </div>

       
                           

                                <div className='img_input mb-3 mt-3'>
                                    <Form>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >

                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
                                                </div>

                                                <div className='col-lg-6 text-center mb-3 mt-3'>
                                                    <Form.Label>Image Preview</Form.Label>
                                                    <div></div>
                                                    {file == "" ?
                                                        <>
                                                            {aboutData?.map((img) => {
                                                                return (
                                                                    <img src={`/assets/cms/${img.image}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <img src={URL.createObjectURL(file)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                                    }

                                                </div>
                                            </div>

                                        </Form.Group>
                                    </Form>

                                </div>

                                <hr></hr>
                                <div>
                                    <Button className='Add_btn_' onClick={handleUpdate}>Update</Button>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default Adminwhychoose
