import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';
// import "react-data-table-component-extensions/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import { FaShare, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import DataTables from '../DataTable/DataTables';
import { MdMessage } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const Banner = () => {


    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [pending, setPending] = useState(true);
    const [message, setMessage] = useState('');


    const [showReply, setShowReply] = useState(false);
    const [productId, setProductId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [productName, setProductName] = useState('');
    const [reply, setReply] = useState('');


    const notify = () => toast.info("Enquiry Reply SuccessFully", { position: "top-center" });
    const notifyStatus = () => toast.info("Enquiry Status Change SuccessFully", { position: "top-center" });
    
    const handleReplyShow = (id) => {
        setShowReply(true);
        data?.filter((data) => data.id === id).map((data) => {
            setProductId(data.id);
            setName(data.name);
            setEmail(data.email);
            setProductName(data.service_id);
            setReply(data.replies);
        })

    }

    const handleReply = () => {
        setShowReply(false);
        setReply('');
        reset({
            replies : ""
        })

    }

    const getData = () => {
        axios.get('/getEnquiry').then((response) => {
            setData(response.data.data);
            setNewData(response.data.data)
            setPending(false);
            console.log(response.data.data)
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    useEffect(() => {
        getData();
    }, []);

    const handleShow = (msg) => {
        setShow(true);
        setMessage(msg);
    }

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post('/enquiryStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notifyStatus();
                window.location.reload(false);
            }
        }).catch((err) => {

            console.log(err);
        })


    }


    const tableData = {
        columns: [
            {
                name: '#',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: (row) => row.name,
                sortable: true
            },
            {
                name: "Email",
                selector: (row) => row.email,
                width: '200px',
                sortable: true,
            },
            {
                name: "Service",
                selector: (row) => row.service_name,
                sortable: true,

            },

            {
                name: "Phone",
                selector: (row) => row.phone,
                width: '200px',
                sortable: true
            },
            {
                name: "Status",
                width: '120px',
                selector: row => <select className=" col-md-4 select_status"
                    value={row.status}
                    id={row.id}
                    onChange={(e) => handleStatus(e)} >
                    <option value="0">Pending</option>
                    <option value="1">Completed</option>

                </select>,
                center: true,



            },
            {
                name: "Message",
                selector: (row) => <Button className='Add_btn_' onClick={() => handleShow(row.message)} ><MdMessage /></Button>,
                width: '100px',

            },
            {
                name: "Action",
                selector: row => <Button className='submit_btn_' onClick={() => handleReplyShow(row.id)} ><FaShare /></Button>,
                width: '100px',

                right: true

            },

        ],

    };


    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status))
        }

    }

    const { register, handleSubmit, reset,formState: { errors } } = useForm();
    const onSubmit = () => {
        axios.post('/enquiryReply', {
            id: productId,
            reply: reply,
            email: email,
            name: name,

        }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            }

        }).then((response) => {
            if (response.status === 200) {
                notify();
                handleReply();
                setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);

            }
        })


    }

    return (
        <>

            <Helmet>
                <title>Walcoseed | Enquiries</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section className="wrapper">
                    <div className="row">
                    <ToastContainer />
                        <div className="col-lg-12">
                            <span className=''>Enquiry</span>
                            <section className="card mt-3">


                                <div className="selectoption mt-4">

                                    <strong >Status :-</strong>
                                    <select className="status filter"

                                        onChange={(e) => filterByStatus(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="0">Pending</option>
                                        <option value="1">Completed</option>
                                    </select>
                                    {/* <span className="refresh" data-toggle="tooltip" title="Refresh">
                                      
                                    </span> */}


                                </div>


                                <div className="main">
                                    <DataTables data={data} columns={tableData.columns} title="Enquiry" />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </section>
                        </div>

                    </div>
                </section>
            </section>

            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Modal.Title>{message}</Modal.Title>

                    {/* <Model.Text>{message}</Model.Text> */}
                </Modal.Body>

            </Modal>


            <Modal show={showReply} onHide={handleReply}>
                <Modal.Header closeButton>
                    <Modal.Title>Reply Enquiry</Modal.Title>

                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name"
                                disabled
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"
                                disabled
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Service Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Product Name"
                                disabled
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Reply</Form.Label>
                            <Form.Control rows={5} as="textarea" placeholder="Type Your Message"
                                value={reply}
                                {...register("ProductName", {
                                    required: true,
                                    onChange: (e) => setReply(e.target.value)
                                })}
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleReply}>
                            Close
                        </Button>
                        <Button className='Add_btn_' type='submit'>
                            Reply
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>





        </>
    )
}

export default Banner