import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from '@mui/material/Avatar';
import '../../../App.css';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import PersonIcon from '@mui/icons-material/Person';
// import CollectionsIcon from '@mui/icons-material/Collections';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { SidebarItems } from './Pages/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/features/authSlice';

// import Logo1 from '../../web/assets/image/logo1.png';
// import Logo from './Assets/Intersection 7.png';
// import Logo from '../../web/assets/image/logo1.png';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




export default function Backend() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState([]);
	const [logo, setLogo] = useState('');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const navigate = useNavigate();
  const handleClose = (e) => {

    setAnchorEl(null);
  };
  const navigate = useNavigate();


  const handleLogOut = () => {
    dispatch(logout())
    navigate('/');
    window.location.reload(false);

  }
  const handleProfile = () => {
    navigate('/profile');
    if(window.innerWidth < 992){
      setOpen(false);
       }
  }
  const handleSettings = () => {

    navigate('/setting');
    if(window.innerWidth < 992){
      setOpen(false);
       }
   
    


  }
  const api_url = process.env.REACT_APP_API_URL;

  console.log(api_url);
  const getData = () => {
		axios.get('/getFooter').then((response) => {
			console.log(response.data);
			setLogo(response.data.data[0].logo)
		  setData(response.data.data)
		}).catch((err) => {
			console.log(err);
		});
}
	useEffect(() => {
		getData();
	}, []);
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <div className='left_h d-flex align-items-center'>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div"> */}
            {open ? null : <img src={`/assets/logo/logo.png`}  />}
            {/* </Typography> */}
          </div>

          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={openMenu ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
            >

              <Avatar sx={{ width: 32, height: 32 }} >A</Avatar>


            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >

            <MenuItem onClick={handleProfile}>
              <Avatar /> Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleSettings}>
              <ListItemIcon >
                <Settings fontSize="small" className='text-black' />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout fontSize="small" className='text-black' />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        className="scroll_"
      >
        <DrawerHeader className='justify-content-between'>
          <img src={`/assets/logo/logo.png`} width="" />
          <IconButton onClick={handleDrawerClose} className="nav_bar">
            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{ color: '#fff' }} /> : <ChevronRightIcon />}
          </IconButton>

        </DrawerHeader>
        <Divider />
        <List>
          {SidebarItems.map((data) => (
            <NavLink end to={`${data.url}`} onClick={window.innerWidth < 992 ? handleDrawerClose : null} activeClassName="active">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {data.icon}
                  </ListItemIcon>
                  <ListItemText primary={data.title} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </List>

      </Drawer>
      <Main open={open} className='admin_main' >
        <DrawerHeader />

        <Outlet />


        {/* <Dashboard /> */}


        {/* <center className='pt-5'>
          <p>
            Copyright @ {new Date().getFullYear()} The Pathway Hypnotherapy Rooms - All rights reserved
          </p>
        </center> */}
      </Main>

    </Box>

  );
}