import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import DataTables from '../DataTable/DataTables';
import { AiFillCodepenSquare } from 'react-icons/ai';
import { MdMarkEmailUnread } from 'react-icons/md';
import { BsFillPersonLinesFill, BsCreditCard } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from "react-helmet";

const Dashboard = () => {

    const [products, setProducts] = useState([]);
    const [enquiry, setEnquiry] = useState([]);
    const [jobEnquiry, setJobEnquiry] = useState([]);
    const [enqData, setEnqData] = useState([]);
    const [pending, setPending] = useState(true);

    const getData = () => {
        axios.get('/getdashboardCount').then((response) => {
            if (response.status === 200) {
                setProducts(response.data.All_Enquiry);
                setEnquiry(response.data.Active_Service);
                setJobEnquiry(response.data.Pending_Enquiry);
            }
        }).catch((err) => {
            
            console.log(err);
          })

        axios.get('/getEnquiry/0').then((response) => {
            setPending(false);
            setEnqData(response.data.data);

        }).catch((err) => {
            setPending(false);
            console.log(err);
          })

    }

    const tableData = {
        columns: [
            {
                name: '#',
                width: '80px',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: (row) => row.name,
                width: '200px',
                sortable: true
            },
            {
                name: "Email",
                selector: (row) => row.email,
                width: '200px',
                sortable: true
            },
            {
                name: "Service",
                selector: (row) => row.service_name,
                sortable: true,
                
            },
            {
                name: "Message",
                selector: (row) => row.message,
                width: '200px',
                sortable: true
            },
            {
                name: "Phone",
                selector: (row) => row.phone,
                sortable: true
            },
           

        ],

    };


    useEffect(() => {
        getData();
        
    }, []);



    return (
        <>

        <Helmet>
            <title>Walcoseed | Admin</title>
        </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className=''>Dashboard</span>

                            <section>
                                <div className='dashboard_card my-4'>
                                    <Link to="/enquiry">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{products}</h1>
                                                    <Card.Title>Total Enquiries</Card.Title>
                                                </div>
                                                <AiFillCodepenSquare className='c_icon' />
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    
                                    <Link to="/service">
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{enquiry}</h1>
                                                    <Card.Title>Services</Card.Title>
                                                </div>
                                                <MdMarkEmailUnread className='c_icon' />
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                    <Link to='/enquiry'>
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Body>
                                                <div>
                                                    <h1>{jobEnquiry}</h1>
                                                    <Card.Title>Pending Enquiries</Card.Title>
                                                </div>
                                                <BsCreditCard className='c_icon' />
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                               
                            </section>

                            <section class="card mt-3">

                                <div className='main'>
                                    <Link to='/enquiry'><Button className='Add_btn_'>
                                        View All
                                    </Button></Link>
                                    <DataTables data={enqData?.slice(0, 5)} title="Enquiries" columns={tableData.columns} pending={pending} />

                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default memo(Dashboard)
