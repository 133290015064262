import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import DataTables from '../DataTable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import TextEditor from '../Editor/TextEditor';
import { FaFilePdf } from 'react-icons/fa'
import download from 'downloadjs';



const Adminproductdoc = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [updatedContent, setUpdatedContent] = useState('');
  const [content,setContent] = useState('');

  const [testimonial, setTestimonial] = useState([]);
  const [image, setServiceimage] = useState('');

  const [updatedImage, setUpdatedImage] = useState('');


  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [allProducts, setAllProducts] = useState([]);

  const [pending, setPending] = useState(true);
  const [file, setFile] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [stars, setStars] = useState('');
  let validationRules = { Today: { required: true } };


  const [showUpdate, setShowUpdate] = useState(false);

  const [product, setProduct] = useState('');

  const [data, setData] = useState([]);
  const [updatedName, setUpdatedName] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedStars, setUpdatedStars] = useState('');

  

  const [Id, setId] = useState('');

  const handleUpdate = () => setShowUpdate(false);


  const handleClose = () => {
    setShow(false);
    setShow1(false);
    
    setFile('');
  };

  const getTestimonial = () => {
    axios.get('/getProductdoclisttable').then((response) => {
      setTestimonial(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  
    axios.get('/getselectProduct').then((response) => {
      console.log(response.data.data)
      setAllProducts(response.data.data);
  }).catch((err) => {
      console.log(err)
  });
  
  }

 

  const getTestimonialcontent = () => {
    axios.get('/getadmintestimonialcontent').then((response) => {
      setContent(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })
  }

  useEffect(() => {
    getTestimonial();
    getTestimonialcontent();
  }, []);


    useEffect(() => { 
    setId(data[0]?.id);
    setUpdatedTitle(data[0]?.list);
    setUpdatedDescription(data[0]?.description);

    

  }, [data]);


  const notifyUpdate = () => toast.info('Document update Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });


  const notify = () => toast.info('Document add Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const addTestimonialSuccess = () => toast.info("Document Added Successfully", { position: "top-center" });
  const removeTestimonialSuccess = () => toast.info("Document Removed Successfully", { position: "top-center" });

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = () => {
    console.log('abc')
    const formData = new FormData();
    formData.append("file", updatedImage);

    axios.post('/addProductdoc', {
      
      doc_title: title,
      product_id: product,
      document:updatedImage,



    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      },

    }).then((response) => {
      if (response.status === 200) {
        handleClose();
        addTestimonialSuccess();
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
        // window.location.reload(false);

      }
    }).catch((err) => {

      console.log(err);
    })
  }

  const deleteTestimonial = (id) => {

    axios.post('/deleteProdoc', { id: id }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      },
    }).then((response) => {
      removeTestimonialSuccess();
      window.location.reload(false);
    }).catch((err) => {

      console.log(err);
    })
  }


  
  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`/getProductdoclisttable/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    axios.post('/updateServicelist', {
      id: Id,
      list: updatedTitle,
      description: updatedDescription,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        handleClose();
        

        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }


  const onUpdate1 = () => {
    setPending(true);
    const formData = new FormData();
    axios.post('/updateServicelist', {
      id:1,
      content: updatedContent,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        handleClose();

        window.location.reload(true);

      }
    }).catch((err) => {

      console.log(err);
    })


  }

  const tableData = {
    columns: [
      {
        name: '#',
        cell: (row, index) => index + 1

      },
      {
        name: "Document name",
        selector: (row) => row.doc_title,
        width: '300px',
        sortable: true
    },

    {
      name: "Product name",
      selector: (row) => row.product_name,
      width: '300px',
      sortable: true
  },
 
  {
    name: "Document",
    selector: (row) => <Button className="_edit_icons" style={{ padding: "8px", margin: '0.5rem' }}
        onClick={() => handleDownload(row.document)}><FaFilePdf fontSize="30px" /></Button>,


    sortable: true,



},



      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='_delete_btn_' onClick={() => deleteTestimonial(row.id)} ><FaTrashAlt /></Button></div>,
        right: true,


      },

    ],

  };
  const handleDownload = (pdf) => {

    const url = `/assets/product/${pdf}`;
    axios.get((url),
        {
            headers: {
                "Content-Type": "application/json",

            },
            responseType: 'blob',
        })
        .then(response => {
            const content = response.headers['content-type'];
            download(response.data, pdf, content);
        })
        .catch(error => console.log(error));
}


  return (
    <>


      <Helmet>
        <title>Walcoseed | Products Documents</title>
      </Helmet>

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <ToastContainer />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>

        <section class="wrapper">
          <div class="row">
            <div class="col-lg-12">
              <span className='p-3'>Service checklist</span>
              <section className='card m-3'>



                <div className='addcareer p-2'>

                  <Button className='Add_btn_' onClick={handleShow}>
                    Add Documents
                  </Button>
                </div>

                <DataTables data={testimonial} columns={tableData.columns} />
              </section>
            </div>
          </div>

        </section>
      </section>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Documents</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>


       

    
    
    <Form.Group className="col-lg-12 col-md-6 col-sm-12 mb-3">
                            <Form.Label>Product</Form.Label>
                            <Form.Select
                                                value={product}
                                                {...register("product", {
                                                    onChange: (e) => setProduct(e.target.value),
                                                    required: 'Please select Product',
                                                    pattern: {
                                                        message: "Please select Product"
                                                    }
                                                })}

                                            >
                                                <option value="">Choose Product</option>
                                                {allProducts?.map((product) => {
                                                    return (

                                                        <option key={product.id} value={product.id}>{product.title}</option>


                                                    )
                                                })}
                                            </Form.Select>

                                            {errors.product && <span className='help-block with-errors text-danger'>{errors.product.message}</span>}

                            </Form.Group>

                            <Form.Group className=" col-sm-12 mb-3">
    </Form.Group>
    <Form.Group className="col-md-12 pb-3">
      <Form.Label>Title</Form.Label>
      <Form.Control
        value={title} placeholder="Enter Title"
        {...register("title", {
          required: true,
          onChange: (e) => setTitle(e.target.value)
        })}
      />
      {errors.title && <div className='errMsg pt-2'>Please Provide Document Name</div>}
    </Form.Group> 

    <Form.Group className="col-md-6 pb-5">
                                            <Form.Label>Document</Form.Label>
                                            <Form.Control type="file"

                                                {...register("document", {
                                                    required: true,
                                                    onChange: (e) => setUpdatedImage(e.target.files[0])
                                                })}

                                            />
                                            {errors.document && <div className='errMsg pt-2'>Please Provide Document</div>}

                                        </Form.Group>



          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='Add_btn_' type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

 

    



    </>
  )
}

export default Adminproductdoc