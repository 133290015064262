import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import {  FaEdit } from 'react-icons/fa';

import axios from 'axios';
import DataTables from '../DataTable/DataTables';
import { FaTrashAlt } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const GalleyAdmin = () => {
    const [show, setShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState([]);
    const [pending, setPending] = useState(true);

    const [showUpdate, setShowUpdate] = useState(false);
    const handleUpdate = () => setShowUpdate(false);

    const [data, setData] = useState([]);

    const handleClose = () => {

        setFile([]);
        setShow(false);
    }


    const addImage = () => toast.info("Image Added Successfully", { position: "top-center" });
    const removeImage = () => toast.info("Image removed Successfully", { position: "top-center" });
    const [updateImg, setUpdateImg] = useState('');
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [UpdatedDescription, setUpdateddescription] = useState('');

    
    const [Id, setId] = useState('');
    const [updatedname, setUpdatedName] = useState('');
    const [updateContent, setUpdateContent] = useState('');
    const [updatedImage, setUpdatedImage] = useState('');
    const [allCategory, setAllCategory] = useState([]);
    const [updatedContent, setUpdatedContent] = useState('');


    

    const getGallery = () => {
        axios.get('/getBanner').then((response) => {
            console.log(response.data.data);
            setGallery(response.data.data);
            setPending(false);

        }).catch((err) => {
            setPending(false);
            console.log(err);
        })


    }




    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {


        const formData = new FormData();
        formData.append('image', file);

        axios.post('/gallery', { image: file }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            },

        }).then((response) => {
            if (response.status === 200) {
                addImage();
                setFile('');
                handleClose();
                setTimeout(() => {
                  window.location.reload(true);
                }, 2000);
            }
        }).catch((err) => {

            console.log(err);
        })
    }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    formData.append("file", updatedImage);
    axios.post('/updateBanner', {
      id: Id,
      banner_image: updatedImage,
      banner_description: UpdatedDescription,
      banner_title: updatedTitle
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);

      }
    }).catch((err) => {

      console.log(err);
    })


  }


    useEffect(() => {
        getGallery();
    }, []);

    const notifyUpdate = () =>   toast.info('Banner update Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const notify = () => toast.info('Banner add Successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    


    const handleShowUpdate = (id) => {
        setShowUpdate(true);
        // getProductName();
        axios.get(`/getadminBanner/${id}`).then((response) => {
          setData(response.data.data);
        }).catch((err) => {
    
          console.log(err);
        })
    
      }
    
      useEffect(() => {
        data?.map((data) => {
    
          setUpdateImg(data.banner_image);
          setUpdatedTitle(data.banner_title);
          setId(data.id);
          setUpdateddescription(data.banner_description);
        })
    
      }, [data])

    const tableData = {
        columns: [
            {
                name: '#',
                width: '70px',
                cell: (row, index) => index + 1
            },
            {
                name: "Page",
                selector: (row) => row.page_name,
                sortable: true
            },
            {
                name: "Banner Title",
                selector: (row) => row.banner_title,
                sortable: true
            },
            
            {
                name: "Image",
                selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/banner/${row.banner_image}`} />,
                sortable: true,
                center: true,
            },

            {
                name: "Action",
                selector: row => <div className='d-flex' style={{ gap: '10px' }}>
                  <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
                </div>,
                right: true
              },

    

        ],

    };



    return (
        <>

            <Helmet>
                <title>Walcoseed | Banner</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                
                <section class="wrapper">
                    <div class="row">
                    <ToastContainer />
                        <div class="col-lg-12">
                            <span className=''>Banner</span>
                            <section className='card mt-3'>
                                <div className='addcareer p-2'>
                                    {/* <Button className="Add_btn_" onClick={handleShow}>
                                        Add Image
                                    </Button> */}
                                </div>

                                <div className='main'>
                                    <DataTables data={gallery} columns={tableData.columns} />
                                </div>


                            </section>
                        </div>
                    </div>
                </section>
            </section>




            {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Banner</Modal.Title>
        </Modal.Header>
        <ToastContainer />
        <Form>
          <Modal.Body>



            <Form.Group className="col-md-12 pb-5">
              
              
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
              />
            </Form.Group>


            <Form.Group
              className="mb-3"

            >


              <Form.Label>Description</Form.Label>
              <Form.Control
                value={UpdatedDescription}
                onChange={(e) => setUpdateddescription(e.target.value)}
              />
            </Form.Group>


            <Form.Group
              className="mb-3"

            >


              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                onChange={(e) => setUpdatedImage(e.target.files[0])}

              />

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/banner/${updateImg}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

            </div>


     


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


        </>
    )
}

export default GalleyAdmin