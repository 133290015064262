import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTables from '../DataTable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import TextEditor from '../Editor/TextEditor';
import { Helmet } from 'react-helmet';
import HTMLRenderer from 'react-html-renderer';

const Productlist = () => {

  const [blog, setBlog] = useState([]);
  const [show, setShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleUpdate = () => setShowUpdate(false);
  const handleClose = () => {
    setDeleteShow(false);
    setShowUpdate(false);
    setShow(false);
    setAddImage('');
    reset({
      name: "",
      image: "",
      content: "",
      title: ""
    })
  }
  const [pending, setPending] = useState(true);

  const [data, setData] = useState([]);

  // add
  const [title, setTitle] = useState('');
  const [addImage, setAddImage] = useState('');
  const [content, setContent] = useState('');


  // update
  const [Id, setId] = useState('');
  const [updateImg, setUpdateImg] = useState('');
  const [updatedname, setUpdatedName] = useState('');
  const [updateContent, setUpdateContent] = useState('');

  const [updatedImage, setUpdatedImage] = useState('');
  const [updatedTitle, setUpdatedTitle] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');


  const [deleteIds, setDeleteIds] = useState('');

  const getBlogs = () => {
    axios.get('/getProductlisttable').then((response) => {
      setBlog(response.data.data);

      console.log(response.data.data);
      setPending(false);
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const handleShow = () => {
    setShow(true);
    // getProductName();

  };

  const notifyDelete = () => toast.success('Product Deleted Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const handleDeleteShow = (e) => {
    // setDeleteShow(true);
    // setDeleteIds(e);
    axios.post('/deleteProduct', {
      id: e

    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setPending(false);
        notifyDelete();
        window.location.reload(5000);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  };

  // blogDelete

  const handleShowUpdate = (id) => {
    setShowUpdate(true);
    // getProductName();
    axios.get(`/getProductlisttable/${id}`).then((response) => {
      setData(response.data.data);
    }).catch((err) => {

      console.log(err);
    })

  }

  useEffect(() => {
    data?.map((data) => {

      setUpdateImg(data.image);
      setUpdateContent(data.description);
      
      setId(data.id);
      setUpdatedName(data.pageName);
      setUpdatedTitle(data.title);
      setUpdatedContent(data.description);

    })

  }, [data])


  const handleStatus = (e) => {
    let id = e.target.id;
    let status = e.target.value;
    axios.post('/loanStatus', {
      id: id,
      status: status,
    }, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    }).catch((err) => {

      console.log(err);
    })
  }



  const tableData = {
    columns: [
      {
        name: '#',
        // width: '50px',
        cell: (row, index) => index + 1
      },
  

      {
        name: 'Title',
        selector: (row) => row.title,
        width: "450px",
      },


      {
        name: "Image",
        selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/product/${row.image}`} />,
        sortable: true,
        width: '350px',
      },
    

      {
        name: "Action",
        selector: row => <div className='d-flex' style={{ gap: '10px' }}>
          <Button className='Add_btn_' onClick={() => handleShowUpdate(row.id)}><FaEdit /></Button>
          <Button className='_delete_btn_ btn btn-primary' onClick={() => handleDeleteShow(row.id)}><FaTrashAlt /></Button>
        </div>,
        right: true,
        width: '150px',
      },

    ],

  };


  useEffect(() => {
    getBlogs();
  }, []);

  const notifyUpdate = () =>   toast.info('Product updated Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const notify = () => toast.info('Product added Successfully', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });



  let validationRules = { Today: { required: true } };

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = () => {
    setPending(true)
    axios.post('/addProduct', {
      title: title,
      image: addImage,
      description: content,
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        setPending(false);
        notify();
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);

      }
    }).catch((err) => {
      setPending(false);
      console.log(err);
    })

  }


  const onUpdate = () => {
    setPending(true);
    const formData = new FormData();
    formData.append("file", updatedImage);
    axios.post('/updateProduct', {
      id: Id,
      image: updatedImage,
      description: updatedContent,
      title: updatedTitle
    }, {
      method: 'POST',
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((response) => {
      if (response.status === 200) {
        notifyUpdate();
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    }).catch((err) => {

      console.log(err);
    })


  }



  return (
    <>
      <Helmet>
                <title>Walcoseed | Products</title>
            </Helmet> 

      <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
      <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>

        <section className="wrapper">

          <div className="row">
          <ToastContainer />
            <div className="col-lg-12">
              <span className=''>Product</span>
              <section className="card mt-3">

                <div className='p-2' >
                  <Button className='Add_btn_' style={{ float: "right" }}
                    onClick={handleShow}
                  >Add Product</Button>
                </div>


                <div className="main">
                  <DataTables data={blog} columns={tableData.columns} />

                </div>
              </section>

            </div>

          </div>
        </section>
      </section>
      {/* add */}
      <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <ToastContainer />
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Modal.Body>
            <Form.Group className=" col-sm-12 mb-3">
    
            </Form.Group>
            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title} placeholder="Enter Title"
                {...register("name", {
                  required: true,
                  onChange: (e) => setTitle(e.target.value)
                })}
              />
              {errors.name && <div className='errMsg pt-2'>Please Provide Title</div>}
            </Form.Group>

            <Form.Group className="col-md-12 pb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={content} placeholder="Enter Description"
                {...register("description", {
                  required: true,
                  onChange: (e) => setContent(e.target.value)
                })}
              />
              {errors.description && <div className='errMsg pt-2'>Please Provide Description</div>}
            </Form.Group>
            
            <Form.Group
              className="mb-3"

            >

              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                {...register("image", {
                  required: true,
                  onChange: (e) => setAddImage(e.target.files[0])
                })} />
              {errors.image && <div className='errMsg pt-2'>Please Provide Image</div>}

              <div className='text-center p-3'>
                <img src={addImage ? URL.createObjectURL(addImage) : ""} style={{ maxWidth: "300px", maxHeight: "300px" }} />
              </div>

            </Form.Group>

          

    
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



      {/* update */}
      <Modal show={showUpdate} onHide={handleUpdate} style={{ zIndex: "9999" }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Product</Modal.Title>
        </Modal.Header>
        <ToastContainer />
        <Form>
          <Modal.Body>



            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
              />
            </Form.Group>
            
            <Form.Group className="col-md-12 pb-5">
              <Form.Label>Description</Form.Label>
              <Form.Control
                value={updatedContent}
                onChange={(e) => setUpdatedContent(e.target.value)}
              />
            </Form.Group>
            
            
            <Form.Group
              className="mb-3"

            >
              <Form.Label>Image</Form.Label>
              <Form.Control type="file"

                onChange={(e) => setUpdatedImage(e.target.files[0])}

              />

            </Form.Group>
            <div className='text-center p-3'>
              <img src={updatedImage == "" ? `/assets/product/${updateImg}` : URL.createObjectURL(updatedImage)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

            </div>





          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className="Add_btn_" onClick={onUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>



    </>
  )
}

export default Productlist