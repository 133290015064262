import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import DataTables from '../DataTable/DataTables';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { set } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const ClientsAdmin = () => {
    const [show, setShow] = useState(false);
    const [clients, setClients] = useState([]);
    const [clientName, setClientName] = useState('');


    const handleShow = () => setShow(true);
    const [file, setFile] = useState('');
    const [pending, setPending] = useState(true);

    const handleClose = () => {
        setClientName('');
        setFile('');
        setShow(false);
    }

    const addClientSuccess = () => toast.info("Client Added Successfully", { position: "top-center" });
    const removeClientSuccess = () => toast.info("Client removed Successfully", { position: "top-center" });

    const getClients = () => {
        axios.get('/getAdminClient').then((response) => {
            console.log(response.data.data);
            setClients(response.data.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    const deleteClient = (id) => {

        axios.post('/DeleteClient', { id: id }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }).then((response) => {
            if (response.status === 200) {
                removeClientSuccess();
                window.location.reload(false);
            }

        }).catch((err) => {

            console.log(err);
        })
    }


    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        const formData = new FormData();
        formData.append("file", file);

        axios.post('/addClient', { name: clientName, image: file }, {
            method: 'POST',
            headers: {
                "Content-Type": "multipart/form-data"
            },

        }).then((response) => {
            if (response.status === 200) {
                addClientSuccess();
                setFile('');
                setClientName('');
                handleClose();
                window.location.reload(false);
            }
        }).catch((err) => {

            console.log(err);
        })
    }


    useEffect(() => {
        getClients();
    }, []);

    const handleStatus = (e) => {
        let id = e.target.id;
        let status = e.target.value;
        axios.post('/clientStatus', {
            id: id,
            status: status,
        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                window.location.reload(false);
            }
        }).catch((err) => {

            console.log(err);
        })


    }

    const tableData = {
        columns: [
            {
                name: '#',
                width: '70px',
                cell: (row, index) => index + 1
            },
            {
                name: "Name",
                selector: (row) => row.name,
                width: '200px',
                sortable: true,
                center: true,
            },
            {
                name: "Image",

                selector: (row) => <img width="50px" height="50px" className='p-2' src={`/assets/client/${row.image}`} />,
                sortable: true,
                center: true,
            },
            {
                name: "Status",
                width: '200px',
                selector: row => <select className=" col-md-4 select_status"
                    value={row.status}
                    id={row.id}
                    onChange={(e) => handleStatus(e)}
                >
                    <option value="0">Active</option>
                    <option value="1">In-Active</option>

                </select>,
                center: true,



            },
            {
                name: "Action",
                selector: row => <div className='d-flex' style={{ gap: '10px' }}>
                    <Button className='_delete_btn_' onClick={() => deleteClient(row.id)} ><FaTrashAlt /></Button></div>,
                right: true,

            },

        ],

    };



    return (
        <>

            <Helmet>
                <title>Walcoseed | Clients</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <ToastContainer />
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12">
                            <span className='p-3'>Clients</span>
                            <section className='card m-3'>
                                <div className='addcareer p-2'>
                                    <Button className="Add_btn_" onClick={handleShow}>
                                        Add Clients
                                    </Button>
                                </div>

                                <div className='main'>
                                    <DataTables data={clients} columns={tableData.columns} />
                                </div>


                            </section>
                        </div>
                    </div>
                </section>
            </section>




            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Client</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control

                                onChange={(e) => setClientName(e.target.value)}
                                value={clientName}
                                type="text"
                                // placeholder="name@example.com"
                                
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file"
                                {...register("image", {
                                    required: true,
                                    onChange: (e) => setFile(e.target.files[0])
                                })} />

                            {errors.image && <div className='errMsg'>Please Provide Client's Image</div>}

                            <div className='text-center p-3'>
                                <img src={file ? URL.createObjectURL(file) : ""} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                            </div>

                        </Form.Group>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="Add_btn_" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default ClientsAdmin