import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import * as React from 'react';
import { Route, Routes, BrowserRouter, HashRouter } from 'react-router-dom';


import Backend from './components/admin/components/Backend';
import Dashboard from './components/admin/components/Pages/Dashboard';
import Login from './components/admin/components/Login/Login';
import Adminabout from './components/admin/components/Pages/Adminabout';
import Profile from './components/admin/components/Login/Profile';
import Enquiries from './components/admin/components/Pages/Enquiries';
import Products from './components/admin/components/Pages/Products';
import AddProducts from './components/admin/components/Pages/AddProducts';
import Setting from './components/admin/components/Pages/Setting';
import TestimonialAdmin from './components/admin/components/Pages/TestimonialAdmin';
import ClientsAdmin from './components/admin/components/Pages/ClientsAdmin';
import UpdateProduct from './components/admin/components/Pages/UpdateProduct';
import GalleyAdmin from './components/admin/components/Pages/GalleryAdmin';
import Adminblog from './components/admin/components/Pages/Blog';
import Adminwhychoose from './components/admin/components/Pages/Adminwhychoose';
import Adminwhygrade from './components/admin/components/Pages/Adminwhygrade';
import Admintreatment from './components/admin/components/Pages/Admintreatment';
import Productlist from './components/admin/components/Pages/Productlist';
import Faq from './components/admin/components/Pages/Faq';
import Adminprivacy from './components/admin/components/Pages/Adminprivacy';
import Admindisclaimer from './components/admin/components/Pages/Admindisclaimer';
import Admingurantee from './components/admin/components/Pages/Admingurantee';
import Adminproductdoc from './components/admin/components/Pages/Adminproductdoc';
import Servicechecklist from './components/admin/components/Pages/Servicechecklist';
import SEO from './components/admin/components/Pages/Seo';
import { useSelector } from 'react-redux';
import ProtectedRoute from './components/admin/components/ProtectedRoute';
import TheGrainDetective from './components/admin/components/Pages/TheGrainDetective';


function App() {
  let auth = localStorage.getItem("auth");
  // const { auth } = useSelector((state) => state?.auth);
  return (
    <>
      <React.Fragment>
        <HashRouter>

          <Routes>
            {auth ?
              <Route path='/' element={<Backend />}>
                <Route index element={<Dashboard />} />
                <Route exact path='/profile' element={<Profile />} />
                <Route exact path='/adminabout' element={<Adminabout />} />
                <Route exact path='/enquiry' element={<Enquiries />} />
                <Route exact path='/service' element={<Products />} />
                <Route exact path="/service/addservice" element={<AddProducts />} />
                <Route exact path="/gallery" element={<GalleyAdmin />} />
                <Route exact path="/setting" element={<Setting />} />
                <Route exact path='/testimonials' element={<TestimonialAdmin />} />
                <Route exact path='/clients' element={<ClientsAdmin />} />
                <Route exact path='/service/updateservice/:id' element={<UpdateProduct />} />
                <Route exact path='/blog' element={<Adminblog />} />
                <Route exact path='/adminwhychoose' element={<Adminwhychoose />} />
                <Route exact path='/adminwhygrade' element={<Adminwhygrade />} />
                <Route exact path='/adminthegraindetective' element={<TheGrainDetective />} />
                <Route exact path='/admintreatment' element={<Admintreatment />} />
                <Route exact path='/servicechecklist' element={<Servicechecklist />} />
                <Route exact path='/productlist' element={<Productlist />} />
                <Route exact path='/faq' element={<Faq />} />
                <Route exact path='/seo' element={<SEO />} />
                <Route exact path='/adminprivacy' element={<Adminprivacy />} />
                <Route exact path='/admindisclaimer' element={<Admindisclaimer />} />
                <Route exact path='/admingurantee' element={<Admingurantee />} />
                <Route exact path='/adminproductdoc' element={<Adminproductdoc />} />
              </Route>
              :
              <Route exact path='/' element={<Login />} />
            }
          </Routes>

        </HashRouter>
      </React.Fragment >
    </>
  );
}

export default App;
