import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import InputGroup from 'react-bootstrap/InputGroup';
const Setting = () => {

    const [smtpData, setSmtpData] = useState([]);
    const [smtpEmail, setSmtpEmail] = useState('');
    const [smtpPassword, setSmtpPassword] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [Address, setAddress] = useState('');
    const [Enqemail, setEnqemail] = useState('');
    const [Map, setMap] = useState('');
    const [Phone, setPhone] = useState('');
    const [Fburl, setFburl] = useState('');
    const [Instaurl, setInstaurl] = useState('');
    const [Twtrurl, setTwtrurl] = useState('');
    const [Logo, setLogo] = useState('');
    const [Footerlogo, setFooterlogo] = useState('');

    const [updatedLogo, setupdatedLogo] = useState('');
    const [updatedFooterlogo, setupdatedFooterlogo] = useState('');

    const [updatedContent, setUpdatedContent] = useState('');
    const [file, setFile] = useState('');
    const [pending, setPending] = useState(true);



    const getAllData = () => {
        axios.get('/getSettings', {

        }).then((response) => {
            console.log(response.data.data);
            setSmtpData(response.data.data);

        }).catch((err) => {

            console.log(err);
        })
    }
    useEffect(() => {
        getAllData();

    }, []);

    const notify = () => toast.info("Setting Update Successfully !", { position: "top-center" });


    useEffect(() => {
        smtpData?.map((smtp) => {
            setSmtpEmail(smtp.smtp_email);
            setSmtpPassword(smtp.smtp_password);
            setAddress(smtp.address);
            setEnqemail(smtp.enq_email);
            setFburl(smtp.fb_url);
            setInstaurl(smtp.insta_url);
            setTwtrurl(smtp.twtr_url);
            setMap(smtp.map_embed);
            setPhone(smtp.phone);
            setLogo(smtp.logo);
            setFooterlogo(smtp.footer_logo);
            setPending(false);
        })
    }, [smtpData]);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = () => {
        setPending(true);

        axios.post('/updateSetting', {
            id: 1,
            smtp_email: smtpEmail,
            smtp_password: smtpPassword,
            address: Address,
            enq_email: Enqemail,
            fb_url: Fburl,
            insta_url: Instaurl,
            twtr_url: Twtrurl,
            map_embed: Map,
            phone: Phone,
            logo: updatedLogo,
            footer_logo: updatedFooterlogo,

        }, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            if (response.status === 200) {
                notify();
                setPending(false);
                window.location.reload(false);
            }
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    // <script> fbq('track', 'ViewContent'); </script> erts5025jldfsdf908gg23kxuncjhju98hcp0743  reflomdevelopment@gmail.com   REf1om#20!2#


    return (
        <>

            <Helmet>
                <title>Walcoseed | Setting</title>
            </Helmet>

            <Spinner animation="border" className={pending ? 'mySpinner' : 'd-none'} />
            <section id="main-content" style={pending ? { opacity: '0.5' } : { opacity: '1' }}>
                <section class="wrapper">
                    <ToastContainer />
                    <div class="row">
                        <div class="col-lg-12">
                            <span className=''>Setting</span>
                            <section class="card mt-3">

                                <div className='settingform'>
                                    <Form className='row p-3' onSubmit={handleSubmit(onSubmit)}>
                                        <div className='col-lg-6'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>SMTP Email</Form.Label>
                                                <Form.Control
                                                    value={smtpEmail}
                                                    type="email"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setSmtpEmail(e.target.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>SMTP Password</Form.Label>
                                                <InputGroup>
                                                <Form.Control autoComplete='new-password' type={showPass ? "text" : "password"}
                                                    value={smtpPassword}
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setSmtpPassword(e.target.value)}
                                                />
                                               
                                                <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => setShowPass(!showPass)}>{showPass ? <AiFillEye /> : <AiFillEyeInvisible />}</InputGroup.Text>
                                            </InputGroup>
                                            </Form.Group>
                                        </div>
                                        <div className='col-lg-12'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control as="textarea" rows={4}
                                                    value={Address}
                                                    type="text"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />

                                            </Form.Group>
                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Enquiry Email</Form.Label>
                                                <Form.Control
                                                    value={Enqemail}
                                                    type="email"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setEnqemail(e.target.value)}
                                                />




                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Fb url</Form.Label>
                                                <Form.Control
                                                    value={Fburl}
                                                    type="text"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setFburl(e.target.value)}
                                                />




                                            </Form.Group>
                                        </div>


                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Twitter url</Form.Label>
                                                <Form.Control
                                                    value={Twtrurl}
                                                    type="text"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setTwtrurl(e.target.value)}
                                                />




                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Instagram url</Form.Label>
                                                <Form.Control
                                                    value={Instaurl}
                                                    type="text"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setInstaurl(e.target.value)}
                                                />



                                            </Form.Group>
                                        </div>


                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    value={Phone}
                                                    type="text"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />



                                            </Form.Group>
                                        </div>


                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Map</Form.Label>
                                                <Form.Control
                                                    value={Map}
                                                    type="text"
                                                    // placeholder="name@example.com"
                                                    autoFocus
                                                    onChange={(e) => setMap(e.target.value)}
                                                />




                                            </Form.Group>
                                        </div>





                                        <div className='col-lg-6'>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Header Logo</Form.Label>
                                                <Form.Control type="file" onChange={(e) => setupdatedLogo(e.target.files[0])} />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <Form.Group

                                                className="d-flex flex-column mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Header Logo Preview</Form.Label>

                                                {updatedLogo == "" ?
                                                    <>
                                                        {smtpData?.map((image) => {
                                                            return (
                                                                <img src={`/assets/logo/${image.logo}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <img src={URL.createObjectURL(updatedLogo)} style={{ maxWidth: "300px", maxHeight: "300px" }} />

                                                }
                                            </Form.Group>
                                        </div>




                                        <div className='col-lg-6 '>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Footer Logo</Form.Label>
                                                <Form.Control type="file" onChange={(e) => setupdatedFooterlogo(e.target.files[0])} />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <Form.Group

                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Footer Logo Preview</Form.Label>
                                                <div></div>
                                                {updatedFooterlogo == "" ?
                                                    <>
                                                        {smtpData?.map((image) => {
                                                            return (
                                                                <img src={`/assets/logo/${image.footer_logo}`} style={{ maxWidth: "100%", maxHeight: "300px" }} />
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    <img src={URL.createObjectURL(updatedFooterlogo)} style={{ maxWidth: "100%", maxHeight: "300px" }} />

                                                }
                                            </Form.Group>
                                        </div>



                                        {/* </div> */}

                                        {/* //####################### */}

                                        {/* <div className='col-lg-6'>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Label>Api Key</Form.Label>
                                                <Form.Control type={showApiKey ? "text" : "password"}
                                                    value={smtpApiKey}
                                                    // placeholder="name@example.com"
                                                    autoFocus 
                                                    onChange={(e) => setSmtpApiKey(e.target.value)}
                                                    />
                                                <div className='_eye_icon' style={{ top: "135px" }} onClick={() => setShowApiKey(!showApiKey)}>
                                                    {showApiKey ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                </div>

                                            </Form.Group>
                                        </div> */}
                                        <div className='text-center'>
                                            <Button className='Add_btn_ m-3' type='submit'>
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>

                                </div>
                            </section>
                        </div>
                    </div >
                </section >
            </section >
        </>
    )
}

export default Setting